.container-fluid {
    padding-top: 70px;
    padding-bottom: 70px;
}
.contents{
    margin: 5rem 20rem;
    font-size: 17px;
    text-align: justify;
}
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.navbar{
    background-color:rgb(225, 249, 249);
}
.nav-heading{
    display: flex;
    text-align: center !important;
    align-items: center !important;
    justify-content: center;
    font-size: 3rem;
    color: #1abc9c;
    

}
.nav-center-items{
    /* display: flex; */
    text-align: center;
    align-items: center;
    justify-content: center;

}
.img1{
    height: 20rem;
    float: left;
    margin-right: 4rem;
    margin-bottom: 2rem;
    border-radius: 4%;
}
.car{
    max-width:80%;
    max-height: 40rem;
    margin: auto;
    border-radius: 2%;
}
.car2{
    max-width:100%;
    max-height: 20rem;
    margin: auto;
    border-radius: 1%;
}
.car1-container{
    max-width: 100%;
}
.car2-container{
    /* float: right; */
    
    max-width: 50%;
    margin: 3rem auto;
    
}
.car3-container{
    /* float: left; */
    max-width: 50%;
    margin: 3rem auto;
}

.content{
    margin: 5rem;
    background-image: "../media/odisha/odisha cultural map.jpeg";
}
.slides{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar {
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 550;
    letter-spacing: 5px;
}
.navbar-nav  li a:hover {
    color: #1abc9c !important;
}
.navbar-brand{
    color: #1abc9c !important;
}
.navbar-image1{
    height: 6rem;
    border-radius: 5%;
    mix-blend-mode:multiply;
}
.navbar-image2{
    height: 6rem;
    border-radius: 5%;
    mix-blend-mode:multiply;
}

/* .footer-padding {
	padding-bottom: 60px;
} */

.footer {
    /* padding-top: 1rem; */
    min-height: 11rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10rem;
    margin-bottom: -1rem;
	/* position: absolute; */
	
	/* bottom: 0; */
	width: 100%;
	/* height: 60px; */
	background-color: #1abc9c;
}
.footer-img{
    height: 2rem;
    margin: 0 1rem;
}

.footer p {
	margin: 0 8rem;
	font-size: 14px;
  color: #fff;
}


.card{
    height: 40rem;
    width: 26rem;
    border-radius: 4%;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
    margin: 2.5rem 4rem;

}
.or-card{
    height: 35rem;
    width: 26rem;
    border-radius: 4%;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
    margin: 2.5rem 4rem;
}
.sm-or-card{
    height: 20rem;
    width: 14.86rem;
    border-radius: 4%;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
    margin: 1.7rem 3rem;
}

.people{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.top{
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom{
    margin-left: 1rem;
    margin-bottom: 1rem;   
}
.sm-bottom{
    margin-left: 0.6rem;
    margin-bottom: 0.6rem;   
}
.card-text{
    margin-bottom: -0.1rem;
    font-size: 16px;
}
.card-name{
    color: #000080;
    font-weight: 600;
}
.sm-card-name{
    color: #000080;

    margin-top: -1rem;
    font-weight: 600;
    font-size: 12px;

}
.img{
    height: 20rem;
    max-width: 20rem;
    border-radius: 3%;
    margin-top: 2rem;
}
.sm-img{
    height: 11.4rem;
    border-radius: 2.5%;
    margin-top: 1rem;
}

.event{
    margin-bottom: 6rem;
}


.gallery{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 1rem;
}
.gallery .pics{
    -webkit-transaction: all 350ms ease;
    transition: all 350ms ease;
    /* cursor: pointer; */
    margin-bottom: 1rem;
}
.gallery .pics:hover{
    filter: opacity(.8);
}
@media(min-width: 480px){
    .navbar-image1{
        height: 13rem;
        position: absolute;
        left:4rem;
        top: 1rem;
        
    }
    .navbar-image2{
        height: 12rem;
        position: absolute;
        right:4rem;
        top: 1rem;

        
    }
}
@media(max-width: 991px){
    .gallery{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        

    }
    .contents{
        margin: 2rem 3rem;
        font-size: 16px;
    }
    .content{
        margin: 1rem;
    }
    .navbar-image2{
        visibility: hidden;
    }
    .navbar-image1{
        visibility: hidden;
    }
}
@media(max-width: 480px){
    .footer{
        display: block;
    }
    .gallery{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
    .contents{
        margin: 1rem 1rem;
        font-size: 12px;
    }
    .content{
        margin: 0.5rem;
    }
    .car{
        max-width: 90%;
    }
    .navbar-image2{
        visibility: hidden;
    }
}
.gallery-img{
    width: "100%";
    display: "block";
    margin: 0.7rem
}

.model{
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;

}

.model .open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    z-index: 999;
}

.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;;
}